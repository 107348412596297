// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-narative-gatsby-theme-novela-templates-articles-template-tsx": () => import("./../src/@narative/gatsby-theme-novela/templates/articles.template.tsx" /* webpackChunkName: "component---src-narative-gatsby-theme-novela-templates-articles-template-tsx" */),
  "component---node-modules-narative-gatsby-theme-novela-src-templates-article-template-tsx": () => import("./../node_modules/@narative/gatsby-theme-novela/src/templates/article.template.tsx" /* webpackChunkName: "component---node-modules-narative-gatsby-theme-novela-src-templates-article-template-tsx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-book-js": () => import("./../src/pages/book.js" /* webpackChunkName: "component---src-pages-book-js" */),
  "component---src-pages-speaking-js": () => import("./../src/pages/speaking.js" /* webpackChunkName: "component---src-pages-speaking-js" */)
}

